import {
  FANS,
  FINANCES,
  UNRESTRICTED_ACCESS,
  SALES,
  ACCOUNTS,
  DAZNACCOUNT,
  PROFILE_BUILDER,
} from './permissions';

// this permission name needs to match the entityname
export const campaign = [FANS, ACCOUNTS];
export const competitionRound = [FANS, ACCOUNTS];
export const competitions = [FANS, DAZNACCOUNT];
export const events = [FANS, ACCOUNTS, DAZNACCOUNT];
export const calendarContent = [FANS, ACCOUNTS];
export const home = [UNRESTRICTED_ACCESS];
export const game1matches = [FANS, ACCOUNTS];
export const gameLeagueRoundPrize = [FANS, ACCOUNTS];
export const gameLeagueMonthPrize = [FANS, ACCOUNTS];
export const gamePrizeMember = [FANS, ACCOUNTS];
export const gamePrize = [FANS, ACCOUNTS];
export const game = [FANS, ACCOUNTS];
export const fixture = [FANS, ACCOUNTS]; // gameFixtures
export const trivias = [FANS, ACCOUNTS];
export const adverts = [UNRESTRICTED_ACCESS];
export const reactivePrize = [FANS, ACCOUNTS];
export const gameBatchPush = [FANS, ACCOUNTS];
export const groupCodes = [FINANCES, SALES, ACCOUNTS];
export const venueCompleter = [ACCOUNTS, PROFILE_BUILDER];
export const venueFinderDataScopes = [UNRESTRICTED_ACCESS];
export const venueCompleterBatch = [ACCOUNTS, PROFILE_BUILDER];
export const league = [FANS, ACCOUNTS];
export const members = [FANS, ACCOUNTS];
export const news = [FANS];
export const offer = [SALES, FANS, ACCOUNTS];
export const pageTvGuide = [FANS];
export const parameter = [SALES, FANS, ACCOUNTS];
export const permission = [];
export const pubPortalCode = [FINANCES];
export const quiz = [FANS, ACCOUNTS];
export const quizAnswer = [FANS, ACCOUNTS];
export const quizMember = [FANS, ACCOUNTS];
export const quizQuestion = [FANS, ACCOUNTS];
export const quizRound = [FANS, ACCOUNTS];
export const quizBatchPush = [FANS, ACCOUNTS];
export const pages = [FANS]; // seoPages
export const socialMediaPostTheme = [];
export const sports = [FANS, DAZNACCOUNT];
export const team = [FANS, DAZNACCOUNT];
export const tier = [FINANCES];
export const venueGroup = [SALES, FINANCES, ACCOUNTS];
export const venues = [UNRESTRICTED_ACCESS];
export const venueMember = [UNRESTRICTED_ACCESS];
export const reports = [SALES, ACCOUNTS, FANS, FINANCES];
export const importVenues = [SALES, ACCOUNTS, DAZNACCOUNT];
export const importEvents = [SALES, ACCOUNTS, DAZNACCOUNT];
export const countryChannel = [SALES, ACCOUNTS, FANS, FINANCES];
export const faq = [UNRESTRICTED_ACCESS];
export const pills = [SALES, FANS, FINANCES];
export const quizThemes = [UNRESTRICTED_ACCESS];
export const venueSocialMediaCompetitionCountry = [UNRESTRICTED_ACCESS];
export const homepage = [FANS];
export const channels = [FANS];
export const bigFixtures = [FANS, DAZNACCOUNT];
export const blogArticle = [FANS];
export const sportPriority = [FANS];
export const compeitionPriority = [FANS];
export const batch = [FANS];
export const errorPage = [UNRESTRICTED_ACCESS];
export const mandates = [FINANCES, ACCOUNTS, SALES];
export const URLShortener = [SALES, FANS, FINANCES, ACCOUNTS];
export const deeplinks = [UNRESTRICTED_ACCESS];
export const assetGroup = [ACCOUNTS];
export const bustCache = [SALES, FANS, FINANCES, ACCOUNTS];
