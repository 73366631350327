import createFormPage from '../formPage';
import CountriesSelector from './component/countriesSelector';
import GameOnboardingTile from './component/GameOnboardingTile';

const params = {
  endpoint: 'games',
  sections: {
    General: [
      'active',
      'name',
      'competitionId',
      'themeId',
      'type',
      'leagueRowImages',
      'sportType',
      'tournament',
      'teamLogoType',
      'hasPintGifting',
      'allowPredictionCompare',
      'scoring',
      'allowFreePotToggle',
    ],
    Theme: ['theme', 'logo', 'gameBackgroundImage', 'prizesHeaderBgImage'],
    'Onboarding Tiles': [
      {
        fieldPath: 'onBoardingTiles',
        Component: GameOnboardingTile,
      },
    ],
    'Homepage Tile': [
      'style.homepageBackgroundImage',
      'buttonText',
      'buttonColour',
      'buttonTextColour',
    ],
    countries: [{ fieldPath: 'id', Component: CountriesSelector }],
    'Branded Copy': [
      'roundHeaderSubtitle',
      'myRoundsText',
      'myPrizesText',
      'prizeTabClaimText',
      'prizeModalClaimLine1',
      'prizeModalClaimLine2',
      'prizeModalGiftLine1',
      'prizeModalGiftLine2',
      'predictionTextPlural',
      'predictionTextSingular',
    ],
    'Terms and conditions': [
      'termsConditionsTitle',
      'termsConditions',
      'termsConditionsDisclaimer',
    ],
  },
  create: {
    fields: ['name', 'active', 'competitionId'],
  },
};

const gamesPage = createFormPage('game', params);

export default gamesPage;
